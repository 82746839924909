// NOTE: Cannot use the Request helper here
// since it depends on the AuthContext for refresh token support
import axios from 'axios';
import Lock from 'browser-tabs-lock';
import { ParsedQuery } from 'query-string';

import apm from '../apm';

const lock = new Lock();
const REQUEST_REFRESH_LOCK_KEY = 'gridos.lock.request.refresh';

export enum AuthLocalStorageKeys {
  POST_LOGIN_REDIRECT = 'postLoginRedirect',
  REFERSH_TOKEN = 'refreshToken',
  REFRESH_TOKEN_EXPIRY = 'refreshTokenExp',
  TOKEN = 'authToken',
  TOKEN_EXPIRY = 'authTokenExp',
}

type EnvironmentInfoResponse = {
  APM: string;
  environment: string;
  version: string;
};

export type LoginData = {
  authorization_endpoint: string;
  audience: string;
  client_id: string;
  scope: string;
  response_type: string;
};

type TokenResponse = {
  authorization_endpoint: string;
  encrypted_bearer_token: string;
  exp: number;
  refresh_token?: string;
  refresh_exp?: number;
  user_email: string;
  user_id: string;
};

type ServiceResponse = {
  apm: boolean;
  auth: boolean;
};

/**
 * Load all initial app data including: available services, environment (version), and login data
 * Initializes APM if needed
 * @param serviceName The name of the service to be reported in APM, if enabled
 */
export async function loadInitialAppData(serviceName: string, baseURL: string) {
  if (baseURL.length > 0 && baseURL.charAt(0) !== '/') {
    baseURL = `/${baseURL}`;
  }

  const { data: services } = await axios.get<ServiceResponse>('/api/services');
  const { data: envInfo } = await axios.get<EnvironmentInfoResponse>(`${baseURL}/environment_info.json`);
  const { data: loginData } = await axios.get<LoginData>('/auth/login');

  if (!services || !envInfo) {
    throw new Error('Could not load service or environment data.');
  }

  if (!loginData) {
    throw new Error('Could not load auth data needed for login.')
  }

  if (services.apm) {
    // APM is enabled so we need to initialize the logging
    apm.configureApm(
      envInfo.APM,
      envInfo.environment,
      `${serviceName}-${envInfo.environment}`,
      envInfo.version,
    );
  }

  return {
    environment: envInfo,
    loginData,
    services,
  };
}

/**
 * Generates a 5 character random string for use as the state
 * passed to the IdP
 */
export function getRandomState() {
  const randomValues = crypto.getRandomValues(new Uint32Array(1));
  return randomValues[0].toString(36).slice(-5);
}

/**
 * Get the URI that we should redirect to in the IdP
 * @param loginCallbackPath The path the IdP should redirect back to after login
 * @param loginData The data about the IdP previously loaded from /auth/login
 */
export function getLoginURI(loginCallbackPath: string, loginData: LoginData) {
  const {
    authorization_endpoint: authEndpoint,
    client_id: clientId,
    response_type: responseType,
    scope,
  } = loginData;

  // Generate a random 5 character string as our state
  const state = getRandomState();

  const redirectUri = `${window.location.origin}${loginCallbackPath}`;
  const uri_state = JSON.stringify({
    state,
    redirect_uri: redirectUri,
  });
  const authCodeUri =
    // Keycloak gets very mad if the scope is encoded, so scope is intentionally not run
    // through encodeURIComponent
    `${authEndpoint}?scope=${scope}` +
    `&response_type=${encodeURIComponent(responseType)}` +
    `&client_id=${encodeURIComponent(clientId)}` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&state=${encodeURIComponent(uri_state)}`;
  return authCodeUri;
}

/**
 * Redirect to the login page of the identity provider
 * @param loginCallbackPath The path the IdP should redirect back to after login
 * @param loginData The data about the IdP previously loaded from /auth/login
 */
export function login(loginCallbackPath: string, loginData: LoginData) {
  // Get the information from the BE to determine where to log-in
  // and then redirect to the identity provider (IdP)
  const loginURI = getLoginURI(loginCallbackPath, loginData);
  window.location.assign(loginURI);
}

/**
 * Remove all auth token state
 */
const removeAuthTokenState = () => {
  localStorage.removeItem(AuthLocalStorageKeys.REFERSH_TOKEN);
  localStorage.removeItem(AuthLocalStorageKeys.REFRESH_TOKEN_EXPIRY);
  localStorage.removeItem(AuthLocalStorageKeys.TOKEN);
  localStorage.removeItem(AuthLocalStorageKeys.TOKEN_EXPIRY);
};

/**
 * Remove all state for the logged-in user and redirect to the IdP logout page
 * @param loginPath The path in the app that we want to come back to after logout. Usually this is the login page
 * @param loginData The data about the IdP previously loaded from /auth/login
 */
export async function logout(loginPath: string, loginData: LoginData) {
  removeAuthTokenState()

  const { authorization_endpoint: authEndpoint, client_id } = loginData;
  let logoutUri = '';

  // The format of the logout URI in the identity provider is different
  // for auth0 and keycloak. Let's detect which one we are using, and construct
  // the URI accordingly
  const auth0Regex= /\/auth$/;
  const keycloakRegex = /\/authorize$/
  const returnAddress = `${window.location.origin}${loginPath}`;

  if (authEndpoint.match(auth0Regex)) {
    logoutUri =
      `${authEndpoint.replace(/\/auth$/, '/logout')}` +
      `?redirect_uri=${encodeURIComponent(returnAddress)}` +
      `&client_id=${client_id}`;
  } else if (authEndpoint.match(keycloakRegex)) {
    logoutUri =
      `${authEndpoint.replace(/\/authorize$/, '/v2/logout')}` +
      `?returnTo=${encodeURIComponent(returnAddress)}` +
      `&client_id=${client_id}`;
  }

  window.location.assign(logoutUri);
}

/**
 * Get the redirect we should go to after login
 */
export function getPostLoginRedirect() {
  const prevLocation = localStorage.getItem(AuthLocalStorageKeys.POST_LOGIN_REDIRECT);

  let redirect = '/';

  if (prevLocation) {
    // The user was on a location and got kicked out.
    // Return them to that location now and remove the token so that this only happens once
    redirect = prevLocation;
    localStorage.removeItem(AuthLocalStorageKeys.POST_LOGIN_REDIRECT);
  }

  return redirect;
}

/**
 * Mark the post login redirect from the user's current path
 */
export function setPostLoginRedirect(path: string) {
  // Set a token to indicate that where to redirect back to
  // after the user re logs in. Prevent this from ever being set
  // to the login page which causes the load to hang
  if (path && !path.startsWith("/login")) {
    localStorage.setItem(AuthLocalStorageKeys.POST_LOGIN_REDIRECT, path);
  }
}


/**
 * Exchange the code & state for an access token
 * @param query Parsed query params that were provided by the IdP upon redirect
 */
async function accessToken(query: ParsedQuery) {
  // We want the Request helper to know about Auth so that it can automatically
  // get the new token as required. Thus, we use axios directly here
  const { data: token } = await axios.get<TokenResponse>(
    '/auth/oauth-callback',
    {
      headers: {
        'X-Proxy-Options': 'raw',
      },
      params: {
        code: query.code,
        state: query.state,
      }
    }
  )
  return token;
}

/**
 * Exchange the code & state for an access token and save it to localStorage
 * @param query parsed query params that were provided by the IdP upon redirect
 */
export const handleAuthCallback = async (query: ParsedQuery) => {
  // Handle the response from the IdP and exchange their data to get
  // a gridos token.
  try {
    const {
      encrypted_bearer_token,
      exp,
      refresh_exp,
      refresh_token,
    } = await accessToken(query);

    localStorage.setItem(AuthLocalStorageKeys.TOKEN, encrypted_bearer_token);
    localStorage.setItem(AuthLocalStorageKeys.TOKEN_EXPIRY, exp.toString());

    if (refresh_exp && refresh_token) {
      localStorage.setItem(AuthLocalStorageKeys.REFERSH_TOKEN, refresh_token);
      localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN_EXPIRY, refresh_exp.toString());
    }

    return true;
  } catch (error) {
    return false;
  }
};


export const getUser = async () => {
  const { data: { permissions, userID, email} } = await axios.get(
    '/api/current_user',
    {
      headers: {
        Authentication: localStorage.getItem(AuthLocalStorageKeys.TOKEN),
      },
    },
  );

  return {
    permissions: new Set<string>(permissions),
    user: {
      email,
      userID,
    },
  };
}


const userIsAuthenticated = () => {
  const missingToken = localStorage.getItem(AuthLocalStorageKeys.TOKEN) === null;
  
  const exp = parseInt(localStorage.getItem(AuthLocalStorageKeys.TOKEN_EXPIRY) || '0', 10);
  const now = new Date().getTime() / 1000;

  return !missingToken && now < exp;
};


/**
 * Valdiate a users current authentication status.
 * Will throw an error if auth is invalid describing why.
 */
export const checkAuth = async () => {
  await refreshTokenIfPossible();

  const userData = await getUser();
  const authValid = userIsAuthenticated();

  if (!authValid) {
    throw new Error('Failed Auth check');
  }

  return userData;
};

/**
 * Redirect the user to the login page, clearing out all auth state
 */
export const redirectToLogin = () => {
  setPostLoginRedirect(window.location.pathname);
  removeAuthTokenState();

  const baseUri = window.location.origin;
  window.location.assign(`${baseUri}/login`);
};

/**
 * Refresh the authToken via the refreshToken and update localStorage
 * @param refreshToken Refresh token to exchange
 */
const refreshAuthToken = async (refreshToken: string) => {
  const body = new FormData();
  body.set('refresh_token', refreshToken);
  const {
    data: {
      encrypted_bearer_token,
      exp,
      refresh_exp,
      refresh_token,
    },
  } = await axios.post<TokenResponse>('/auth/refresh', body);

  localStorage.setItem(AuthLocalStorageKeys.TOKEN, encrypted_bearer_token);
  localStorage.setItem(AuthLocalStorageKeys.TOKEN_EXPIRY, exp.toString());

  if (refresh_exp && refresh_token) {
    localStorage.setItem(AuthLocalStorageKeys.REFERSH_TOKEN, refresh_token);
    localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN_EXPIRY, refresh_exp.toString());
  }
}

/**
 * Determine if the authToken is expired and try to refresh it via the refreshToken
 * Will throw if this cannot be achieved
 */
export const refreshTokenIfPossible = async () => {
  let now = (new Date()).getTime() / 1000;
  const expString = localStorage.getItem(AuthLocalStorageKeys.TOKEN_EXPIRY);

  if (expString === null) {
    // There is no auth data. Auth might be disabled, just skip
    return;
  }

  let exp = parseInt(expString);

  if (now > exp) {
    // Token is expired, try and refresh
    const refreshToken = localStorage.getItem(AuthLocalStorageKeys.REFERSH_TOKEN);
    const refreshExpiry = localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN_EXPIRY);

    if (refreshToken && refreshExpiry !== null && now < parseInt(refreshExpiry)) {
      // Non-expired refresh token. Try to refresh
      let error = false;
      try {
        await lock.acquireLock(REQUEST_REFRESH_LOCK_KEY, 5000);

        // We check this again inside the critical section because parallel
        // requests could have refreshed the token
        now = (new Date).getTime() / 1000;
        exp = parseInt(localStorage.getItem(AuthLocalStorageKeys.TOKEN_EXPIRY) || '0');

        if (now > exp) {
          await refreshAuthToken(refreshToken);
        }
      } catch  {
        // If any error occurs, we need to go back to the login page
        error = true;
      } finally {
        await lock.releaseLock(REQUEST_REFRESH_LOCK_KEY);
      }

      if (error) {
        // Do this after the try-catch so that the lock is always released
        redirectToLogin();
        throw new Error('Could not exchange refresh token for auth token');
      }
    } else {
      redirectToLogin();
      throw new Error('Auth token is expired and no refresh token exists');
    }
  }
};
