import { useEffect, useState, ReactNode } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import { useToasts } from 'react-toast-notifications';

import apm from './apm';
import Request from './Request';


type RequestHookState<DataType> = {
  data?: DataType;
  error?: AxiosError;
  loading: boolean;
};

interface MakeRequestProps<DataType> extends AxiosRequestConfig {
  blockRequest?: () => boolean;
  body?: any;
  dataTransform?: (data: any) => DataType;
  method?: 'delete' | 'get' | 'patch' | 'post' | 'put';
  onError?: (error: AxiosError) => void;
  onSuccess?: (data: Optional<DataType>, headers: { [key: string]: string }) => any;
  toast?: {
    error?: ReactNode | ((error: AxiosError) => ReactNode);
    settings?: {
      autoDismiss?: boolean;
      onDismiss?: (id: string) => void;
    };
    success?: ReactNode;
  };
}

type Optional<T> = T | undefined;

interface RequestEffectProps<DataType> extends MakeRequestProps<DataType> {
  refetchOnChange?: any[];
  initialData?: DataType;
  url?: string;
  urlGen?: () => string;
}

export function useRequest<DataType = any>(url: string, initialData: Optional<DataType> = undefined) {
  const [dataState, setDataState] = useState<RequestHookState<DataType>>({
    data: initialData,
    error: undefined,
    loading: false
  });

  const { addToast } = useToasts();

  async function makeRequest(
    {
      method,
      onSuccess,
      onError,
      toast,
      dataTransform,
      blockRequest,
      body,
      ...options
    }: MakeRequestProps<DataType> = {},
    didCancel?: () => boolean
  ): Promise<AxiosPromise | void> {
    if (didCancel && didCancel()) return;
    if (typeof method === 'undefined') {
      return;
    }

    try {
      setDataState({
        ...dataState,
        error: undefined,
        loading: true
      });

      const block = blockRequest ? blockRequest() : false;
      let data: Optional<DataType>;
      let headers;

      if (!block) {
        const req = new Request(url);
        const requiresBody = ['post', 'put', 'patch'].includes(method);
        const response = await req[method](
          // @ts-ignore: We know we are calling this safely
          ...(requiresBody ? [body, options] : [options])
        );
        data = response.data;
        headers = response.headers;
      } else {
        data = initialData;
      }

      data = dataTransform ? dataTransform(data) : data;

      setDataState({
        loading: false,
        data
      });

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data, headers);
      }

      if (toast?.success && typeof toast?.success === 'string') {
        addToast(toast.success, { appearance: 'success', ...toast.settings });
      }
    } catch (error) {
      // We only want to log critical errors
      if (error.response?.status === 500) {
        apm.captureError(error);
      }

      setDataState({ loading: false, error });

      if (onError && typeof onError === 'function') {
        onError(error);
      }

      if (toast?.error) {
        addToast(
          typeof toast.error === 'function' ? toast.error(error) : toast.error,
          { appearance: 'error', ...toast.settings }
        );
      }
    }
  }

  return { makeRequest, ...dataState };
}

export function useRequestEffect<T>({
  url,
  urlGen,
  refetchOnChange = [],
  initialData = undefined,
  ...requestParams
}: RequestEffectProps<T>) {
  if (urlGen) {
    url = urlGen()
  }

  if (!url) {
    throw new Error('URL is not set');
  }

  const { makeRequest, loading, error, data } = useRequest<T>(url, initialData);
  const [refetch, setRefetch] = useState(false);

  // Base request
  useEffect(() => {
    let didCancel = false;
    makeRequest(requestParams, () => didCancel);
    return () => {
      didCancel = true;
    };

    // Use effect is very sensitive checking dep updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchOnChange.concat(url));

  // Manual refetch
  useEffect(() => {
    let didCancel = false;
    if (refetch) {
      makeRequest(requestParams, () => didCancel);
      setRefetch(false);
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return { loading, error, data, refetch: () => setRefetch(true) };
}
