import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import qs from 'query-string';

import { AuthLocalStorageKeys, refreshTokenIfPossible, redirectToLogin } from './AuthContext/AuthAPI';

const { CancelToken } = axios;

class Request {
  cancelToken: CancelTokenSource
  url: string;
  wasCancelled: boolean;

  constructor(url: string) {
    this.url = url;
    this.cancelToken = CancelToken.source();
    this.wasCancelled = false;
  }

  static cancelled = (error: any) => axios.isCancel(error);

  async get(options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.get(this.url, this.updateOptions(options)));
  }

  async post(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.post(this.url, data, this.updateOptions(options)));
  }

  async put(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.put(this.url, data, this.updateOptions(options)));
  }

  async patch(data: any, options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.patch(this.url, data, this.updateOptions(options)));
  }

  async delete(options: AxiosRequestConfig = {}) {
    options.headers = options.headers || {};
    return this.catchUnauthorized(() => axios.delete(this.url, this.updateOptions(options)));
  }

  cancel(message?: string) {
    this.cancelToken.cancel(message);
    this.wasCancelled = true;
  }

  updateOptions(options: AxiosRequestConfig): AxiosRequestConfig {
    options = options || {};
    return {
      ...options,
      cancelToken: this.cancelToken.token,
      headers: {
        ...(options.headers || {}),
        Authentication: localStorage.getItem(AuthLocalStorageKeys.TOKEN),
        'X-Proxy-Options': 'raw',
      },
      paramsSerializer: params => qs.stringify(params),
    };
  }

  async catchUnauthorized(makeRequest: () => Promise<AxiosResponse>): Promise<AxiosResponse> {
    await refreshTokenIfPossible();

    try {
      const request = makeRequest();
      return await request;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        redirectToLogin();
      }
      throw error;
    }
  }
}

export default Request;
