import React, { ReactChild } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthContext } from './AuthContext';
import { setPostLoginRedirect } from './AuthContext/AuthAPI';

interface ProtectedRouteProps extends RouteProps {
  children: ReactChild;
}

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const { isLoading, isAuthEnabled, isAuthenticated, loginPath } = useAuthContext();

  if (!isAuthenticated) {
    // We're going to redirect the user so we should mark the path such that the
    // user will come back here later
    setPostLoginRedirect(window.location.pathname);
  }

  return (
    !isLoading ? (
      <Route {...rest}>
        {isAuthenticated || !isAuthEnabled ? (
          children
        ) : (
          <Redirect to={loginPath} />
        )}
      </Route>
    ) : null
  );
};

export default ProtectedRoute;